import React from "react"
const CloseIcon = ({ fill, width, height }: { fill?: string, width?: string, height?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_188_26826)">
        <path
          d="M12.5 3.5L3.5 12.5"
          stroke="#A7A7A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 12.5L3.5 3.5"
          stroke="#A7A7A7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_188_26826">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CloseIcon;
