import { Drawer, PaperProps } from "@mui/material";

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface SMDrawer$Props {
    cartOpen: boolean;
    onClose: () => void;
    anchor: Anchor;
    renderview: any;
    paperProps?:Partial<PaperProps<React.ElementType<any>>>;
}

  
export const SMDrawer: React.FunctionComponent<SMDrawer$Props> = ({ cartOpen, onClose, anchor, renderview,paperProps }) => {
    return (
     <Drawer anchor={anchor} open={cartOpen} onClose={onClose}  PaperProps= {paperProps || { sx: { width: "23%",  backgroundColor: "#6B787F", color: 'white' } }}>
        {renderview}
      </Drawer>
    );
  };
  