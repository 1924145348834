import { Container, Grid, styled } from "@mui/material";

export const PageContainer = styled(Container)({
    margin: "2rem auto",
    width: "calc(100% - 4rem)",
    maxWidth: "1600px",
})

export const BoxedContent = styled(Grid)({
    background: "#fff",
    width: "100%",
    padding: "0 2rem 2rem",
    flex: 1
})

export const BoxedContentHeader = styled(Grid)({
    margin: "0",
    padding: "16px 0 8px",
    display: "flex",
    alignItems:"center",
    flex: 1,
    justifyContent:"space-between"
});