import { IUser } from "../account/user";

export interface ISurvey {
  title: string;
  id: string;
  status: string;
  respondents: number;
  informationMarker: string;
  pages: ISurveyPage[];
  responses?: string;
  logoUrl: string;
  lastModified: string;
  navigation: ISurveyNavigation;
  canEdit: boolean;
  collaborating: boolean;
  eventSurvey: boolean;
}

export interface IGetSurveysResponse {
  total: number,
  surveys: ISurvey[]
}

export interface ISurveyNavigation {
  editUrl: string;
  copyUrl: string;
  settingsUrl: string;
  manageUrl: string;
  analyzeUrl: string;
  launchUrl: string;
  previewUrl: string;
}

export interface ISurveyPage {
  id: string;
  title?: string;
  description?: string;
  questions: ISurveyQuestion[];
}

export interface ISurveyFolder {
  id: string;
  name: string;
}

export interface ISurveyQuestion {
  id: string;
  text: string;
  choices: IAnswerChoice[];
  questionType: QuestionTypes;
  options: IQuestionOptions;
  logic: IQuestionLogic[];
  starConfig: IStarConfig;
  matrixConfig: IMatrixConfig;
  additionalComments: IAdditionalComments;
  otherChoice: IOtherChoice;
  misc: IMisc;
}

export interface IMisc {
  fileExtensions: string
}
export interface IAdditionalComments {
  enabled: boolean,
  layout: string,
  maxCharacters: number,
  label: string
  points: number
}

export interface IOtherChoice {
  enabled: boolean,
  layout: string
  label: string
}
export interface IMatrixConfig {
  rows: IMatrixRow[];
  columns: IMatrixColumn[];
  useWeights: boolean,
  singleRow: boolean,
  rankedQuestion: boolean
}

export interface IMatrixRow {
  id: string;
  text: string;
}

export interface IMatrixColumn {
  id: string;
  text: string;
  weight: number;
}

export interface IAnswerChoice {
  id: string;
  text: string;
  hidden?: boolean;
  points?: number
}

export interface ILabel {
  id: string;
  text: string;
}

export interface IQuestionOptions {
  requireAnswer: boolean;
  randomiseChoices: boolean;
  layout: string,
  score: boolean,
  validationsRules: IValidationRules,
  choiceLayout?: boolean
}

export interface IQuestionLogic {
  choiceId: string;
  page: string;
  question: string;
}

export interface IStarConfig {
  scale: number;
  colorHex: string;
}

export type EditOptionEnum = "edit" | "options" | "logic" | "move" | "copy";

export type IQuestionOptionsKey = "requireAnswer" | "randomiseChoices" | "layout" | "choiceLayout"

export type RequiredType = "exactly" | "atleast" | "atmost" | "between" | ""

export interface IValidationRules {
  requiredType: RequiredType,
  atLeast?: number,
  atMost?: number,
  message: string
}

export type QuestionTypes =
  | "multiplechoice"
  | "dropdown"
  | "checkboxes"
  | "starrating"
  | "singletextbox"
  | "multipletextboxes"
  | "matrixratingscale"
  | "commentsbox"
  | "text"
  | "image"
  | "file-upload"
  | "name"
  | "emailaddress"
  | "matrixofcheckboxes"
  | "ranking";

export interface IManipulationResult {
  questionId: string;
  pageId: string;
  beforeAfter: string;
  targetQuestionId: string;
}

export interface IUploadLogo {
  surveyId: string;
  file: File | null;
}

export interface IDeletedQuestions {
  title: string;
  id: string;
  status: string;
  responses?: string;
  logoUrl: string;
  questions: any[];
}

export interface IDragDropQuestionSave {
  pageId: string;
  beforeAfter: string;
  targetQuestionId: string;
  questionModel:ISurveyQuestion;
}
export interface IMoveSurveyFolderRequest {
  surveyId: string | null;
  folderId: string | null;
}
export interface IBuildItemList {
  displayTitle: string;
  passValue: string;
  icon: React.ReactNode;
}

export interface ILogicItemList {
  icon: React.ReactNode;
  title: string;
  status: boolean;
}

export interface IFormatItemList {
  icon: React.ReactNode;
  title: string;
  innerValue: boolean;
}
export interface IOptionsItemList {
  icon: React.ReactNode;
  title: string;
  toggle: boolean;
  language?: boolean;
}
export interface IDesignSurveyMenuList {
  icon: React.ReactNode;
  title: string;
}
export interface IFolder {
  handleClose: (arg: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  folderList: ISurveyFolder[];
  folderOpen: Boolean;
  handleNewFolderClick: (
    arg: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  handleSaveClick: (arg: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  newFolder: string;
  onFieldChange: (
    arg: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleSelectFolder: (id: string) => void;
  isLoading: boolean;
  selectedFolderId: string | null;
}
export interface IHeaderMobileDrawerView {
  handleDrawerClose: (
    arg: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void;
  navigate: any;
  user: IUser;
}
export enum alertMessageType {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
}
export interface ILayoutType {
  label: string,
  value: string
}

export interface IChoiceList {
  title: string,
  value: string
}