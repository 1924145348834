import {styled} from "@mui/material";

export const Heading2 = styled("h2")({
    fontSize: "2rem",
    marginBottom: "16px",
    fontWeight:600
})

export const Heading3 = styled("h3")({
    fontSize: "1.1rem",
    marginBottom: "16px",
    fontWeight:600
})

export const Heading5 = styled("h5")({
    fontSize: "1rem",
    fontWeight: 400
})

export const HR = styled("hr")({
    background: "rgba(167, 167, 167, 0.2)",
    borderWidth: 0,
    height: "1px",
    width: "100%"
})

export const SMHeading1 = styled("h1")({
  color: "#3F475D",
  fontSize: "16px",
  fontWeight: 500,
  margin: 0,
  lineHeight: "24px",
});

export const SMHeading2 = styled("h2")({
  color: "#242836",
  fontSize: "14px",
  fontWeight: 500,
  margin: 0,
});

export const SMBody = styled("p")({
  color: "#3F475D",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "20px",
  margin: 0,
});

export const SMHeaderText = styled("h1")({
  color: "#242836",
  fontSize: "24px",
  fontWeight: 600,
  margin: "0",
  '@media (max-width: 900px)': {
    fontSize: '18px',
  },
})

export const SMTitle = styled("h1")({
  color: "#242836",
  fontSize: "18px",
  fontWeight: 600,
  margin: "0"
})

export const SMTitle2 = styled("h2")({
  color: "#242836",
  fontSize: "18px",
  fontWeight: 500,
  margin: "0"
})

export const SMDetailText = styled("p")({
  color: "#242836",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "0",
})

export const SMDetailText1 = styled("p")({
  color: "#242836",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
})

export const SMText = styled("p")({
  color: "#3F475D",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "0",
})

export const SMTableHeaderText = styled("span")({
  color: "#242836",
  fontSize: "18px",
  fontWeight: 600,
})

export const SMContentText = styled("p")({
  color: "var(--Text- Text - dark - grey, #3F475D)",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "18px"
})

export const SMErrorText = styled("span")({
  color: "#E24240",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
})

export const SMTitleText = styled("span")({
  color: "#242836",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
})
