import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import Layout from "../components/layout";
import CustomSnackbar from "../components/snackbar/snackbar";

const SurveyHome = lazy(() => import("../components/surveys/home"));
const AnalyzeSurvey = lazy(() => import("../components/surveys/analyse"));
const SurveyCreator = lazy(() => import("../components/surveys/create"));
const NewSurvey = lazy(() => import("../components/surveys/create/create-survey"));

const useRenderRoutes = () =>
  useRoutes([
    {
      element: (
        <React.Fragment>
          <Layout />
          <CustomSnackbar />
        </React.Fragment>
      ),
      children: [
        { index: true, element: <SurveyHome /> },
        { path: "/create", element: <NewSurvey /> },
        { path: "/editor", element: <SurveyCreator /> },
        { path: "/analyze/:surveyId/summary", element: <AnalyzeSurvey /> },
      ],
    },
  ]);

const renderRoutes = { useRenderRoutes };

export default renderRoutes;
