import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import AccountService from "../services/accounts-service";
import { IAccountState } from "../types/account/account-state";
import { IUser } from "../types/account/user";

const initialState: IAccountState = {
  loading: false,
  user: null,
  failed: false,
};

const fetchUser = createAsyncThunk(
  "accounts/me",
  async (_, { rejectWithValue }) => {
    try {
      const res = await AccountService.getUser();
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state: IAccountState, _) => {
        state.loading = true;
        state.failed = false;
        state.user = null;
      })
      .addCase(
        fetchUser.fulfilled,
        (state: IAccountState, action: PayloadAction<IUser | null>) => {
          state.loading = false;
          state.failed = false;
          state.user = action!.payload!;
        }
      )
      .addCase(fetchUser.rejected, (state: any, action: any) => {
        state.loading = false;
        state.failed = true;
        state.user = null;
      });
  },
});

const getUser = (state: RootState) => state.account.user;

export { fetchUser, getUser };

export default accountsSlice.reducer;
