import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import AnalyzeService from "../services/analyze-service";
import {
  IAnalyzeState,
  IReportSummary
} from "../types/surveys/analyze-state";

const initialState: IAnalyzeState = {
  reportSummary: {
    loading: false,
    summary: null,
    error: false,
  },
};

const fetchReportSummary = createAsyncThunk(
  "analyze/getresults",
  async (surveyId: string, { rejectWithValue }) => {
    try {
      const res = await AnalyzeService.getResultSummary(surveyId);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

export const analyzeSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportSummary.pending, (state: IAnalyzeState, _) => {
        state.reportSummary = {
          loading: true,
          error: false,
          summary: null,
        };
      })
      .addCase(
        fetchReportSummary.fulfilled,
        (
          state: IAnalyzeState,
          action: PayloadAction<IReportSummary | null>
        ) => {
          state.reportSummary = {
            loading: false,
            error: false,
            summary: action!.payload!,
          };
        }
      )
      .addCase(
        fetchReportSummary.rejected,
        (state: IAnalyzeState, action: any) => {
          state.reportSummary = {
            loading: false,
            error: true,
            summary: null,
          };
        }
      );
  },
});

const getReportSummary = (state: RootState) => state.analyze.reportSummary;

export { fetchReportSummary, getReportSummary };

export default analyzeSlice.reducer;
