// All user related database operations can be defined here.

import {
    GET_RESULT_SUMMARY,
  } from "./CONSTANTS";
  import http from "../http-common";
  
  class AnalyzeDataService {
    getResultSummary(surveyId: string) {
      return http.get(GET_RESULT_SUMMARY(surveyId), { withCredentials: true});
    }
  }
  
  const dataservice = new AnalyzeDataService();
  
  export default dataservice;
  