// All user related database operations can be defined here.
import {
    GET_USER,
  } from "./CONSTANTS";
  import http from "../http-common";
  
  class AccountsDataService {
    getUser() {
      return http.get(GET_USER, { withCredentials: true});
    }
  }
  
  const dataservice = new AccountsDataService();
  
  export default dataservice;
