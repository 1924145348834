const EnvelopeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_1142_2359)">
                <path d="M21 5.25L12 13.5L3 5.25" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3 5.25H21V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H3.75C3.55109 18.75 3.36032 18.671 3.21967 18.5303C3.07902 18.3897 3 18.1989 3 18V5.25Z" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.363 12L3.23047 18.5381" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.7692 18.5381L13.6367 12" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1142_2359">
                    <rect width="24" height="24" fill="#9297A8" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default EnvelopeIcon;
