import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import SurveyService from "../services/survey-service";
import {
  IDeletedQuestions,
  IDragDropQuestionSave,
  IMoveSurveyFolderRequest,
  ISurvey,
  IGetSurveysResponse,
  ISurveyFolder,
  ISurveyQuestion,
  ISurveyState,
  alertMessageType,
  IAlertMessageState
} from "../types/surveys";
import { ADD_FOLDER_FAILED, ADD_FOLDER_SUCCESS, DELETE_SURVEY_FAILED, DELETE_SURVEY_SUCCESS, FOLDER_ALREADY_EXISTS, MOVE_FOLDER_FAILED, MOVE_FOLDER_SUCCESS, QUESTION_SAVE_FAILED, QUESTION_SAVE_SUCCESS } from "../services/validation-message";

const initialState: ISurveyState = {
  surveys: {
    loading: false,
    failed: false,
    total: 0
  },
  surveyFolders: {
    loading: false,
    folders: [],
    failed: false,
  },
  activeSurvey: {
    loading: false,
    survey: null,
    failed: false,
  },
  updating: {
    processing: false,
    processed: false,
    failed: false,
  },
  inActiveQuestions : {
    deletedquestion: null,
    loading: false,
    failed: false
  },
  alertMessage: {
    show: false,
    message: "",
    type: alertMessageType.Success
  }
};

const fetchSurveys = createAsyncThunk(
  "surveys/get",
  async ({ folderId, searchTerms,page, limit  }: any, { rejectWithValue }) => {
    try {
      const res = await SurveyService.getSurveys(folderId, searchTerms,page,limit );
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const fetchSurvey = createAsyncThunk(
  "survey/get",
  async (surveyId: string, { rejectWithValue }) => {
    try {
      const res = await SurveyService.getSurvey(surveyId);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const fetchSurveyFolders = createAsyncThunk(
  "surveys/getfolders",
  async (_, { rejectWithValue }) => {
    try {
      const res = await SurveyService.getSurveyFolders();
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const alertMessageDispatch = (message: string, type: alertMessageType, dispatch: any) => {
  dispatch(
    alertMessageAction({
      message: message,
      type: type,
      show: true
    })
  );
}
export interface IAddSurveyFolder {
  folderName: string;
}

const addSurveyFolders = createAsyncThunk(
  "surveys/addfolders",
  async (args: IAddSurveyFolder, { rejectWithValue, dispatch }) => {
    try {
      const res = await SurveyService.addSurveyFolders(args.folderName);
      if (res.status === 200) {
        alertMessageDispatch(ADD_FOLDER_SUCCESS, alertMessageType.Success, dispatch)
        dispatch(fetchSurveyFolders());
      }
      return res.data;
    } catch (err: any) {
      if (err.response.data.errors[0] === "FOLDER_ALREADY_EXISTS") {
        alertMessageDispatch(FOLDER_ALREADY_EXISTS, alertMessageType.Error, dispatch)
      }
      else {
        alertMessageDispatch(ADD_FOLDER_FAILED, alertMessageType.Error, dispatch)
      }
      if (err.response.status === 404) {
        return null;
      }
      return rejectWithValue(err);
    }
  }
);

export interface IUpdateSurveyRequest {
  surveyId: string;
  model: ISurvey;
}

const updateSurveyAsync = createAsyncThunk(
  "surveys/update",
  async (args: IUpdateSurveyRequest, { rejectWithValue, dispatch }) => {
    try {
      const res = await SurveyService.updateSurvey(args.surveyId, args.model);
      alertMessageDispatch(QUESTION_SAVE_SUCCESS, alertMessageType.Success, dispatch)
      return res.data;
    } catch (err: any) {
      alertMessageDispatch(QUESTION_SAVE_FAILED, alertMessageType.Error, dispatch)
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const moveSurveyQuestion = createAsyncThunk(
  "surveys/movequestion",
  async ({ surveyId, args }: any, { rejectWithValue }) => {
    try {
      const res = await SurveyService.moveSurveyQuestion(surveyId, args);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const deleteSurvey =  createAsyncThunk(
  "surveys/delete",
  async (
    surveyId: string,
    { rejectWithValue,dispatch }
  ) => {
    try {
      const res = await SurveyService.deleteSurvey(surveyId);
      alertMessageDispatch(DELETE_SURVEY_SUCCESS, alertMessageType.Success, dispatch)
      return res.data;
    } catch (err: any) {
      alertMessageDispatch(DELETE_SURVEY_FAILED, alertMessageType.Error, dispatch)
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const moveSurveyFolder = createAsyncThunk(
  "surveys/movefolder",
  async (
    { surveyId, folderId }: IMoveSurveyFolderRequest,
    { rejectWithValue,dispatch }
  ) => {
    try {
      const res = await SurveyService.moveSurveyFolder({
        surveyId,
        folderId,
      });
      alertMessageDispatch(MOVE_FOLDER_SUCCESS, alertMessageType.Success, dispatch)
      return res.data;
    } catch (err: any) {
      alertMessageDispatch(MOVE_FOLDER_FAILED, alertMessageType.Error, dispatch)
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const copySurveyQuestion = createAsyncThunk(
  "surveys/copyquestion",
  async ({ surveyId, args }: any, { rejectWithValue }) => {

    try {
      const res = await SurveyService.copySurveyQuestion(surveyId, args);
      return res.data;
    } catch (err: any) {
      console.log(err)
      if (err.response.status === 404) {
        return null;
      }
      return rejectWithValue(err);
    }
  }
);

const addDragDropSurveyQuestion = createAsyncThunk(
  "surveys/dragadropquestion",
  async (data: IAddDragDropSurveyQuestionRequest, { rejectWithValue }) => {

    try {
      const res = await SurveyService.addDragDropSurveyQuestion(data.surveyId, data.model);
      return res.data;
    } catch (err: any) {
      console.log(err)
      if (err.response.status === 404) {
        return null;
      }
      return rejectWithValue(err);
    }
  }
);


export interface IUpdateSurveyOptionsRequest {
  surveyId: string;
  question: ISurveyQuestion;
}

export interface IUpdateSurveyLogicRequest {
  surveyId: string;
  question: ISurveyQuestion;
}

export interface IAddDragDropSurveyQuestionRequest {
  surveyId: string;
  model: IDragDropQuestionSave;
}

const updateSurveyOptions = createAsyncThunk(
  "surveys/updateoptions",
  async (
    { surveyId, question }: IUpdateSurveyOptionsRequest,
    { rejectWithValue }
  ) => {
    try {
      const res = await SurveyService.updateQuestionOptions(
        surveyId,
        question.id,
        question.options
      );
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const updateSurveyLogic = createAsyncThunk(
  "surveys/updatelogic",
  async (
    { surveyId, question }: IUpdateSurveyLogicRequest,
    { rejectWithValue }
  ) => {
    try {
      const res = await SurveyService.updateQuestionLogic(
        surveyId,
        question.id,
        question.logic
      );
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);


const uploadLogo = createAsyncThunk(
  "surveys/upload",
  async (
    { data }: any,
    { rejectWithValue }
  ) => {
    try {
      const res = await SurveyService.uploadLogo(data);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const deleteLogo = createAsyncThunk(
  "surveys/deletelogo",
  async (
    surveyid: string,
    { rejectWithValue }
  ) => {
    try {
      const res = await SurveyService.deleteLogo(surveyid);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const deleteQuestion = createAsyncThunk(
  "surveys/deletequestion",
  async (args: IDeleteQuestion, { rejectWithValue }) => {
    try {
      const res = await SurveyService.deleteQuestion(args.surveyId, args.questionId);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const fetchDeletedQuestions = createAsyncThunk(
  "surveys/getdeletedquestions",
  async (surveyId: string, { rejectWithValue }) => {
    try {
      const res = await SurveyService.getDeletedQuestions(surveyId);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

const restoreDeletedQuestions = createAsyncThunk(
  "surveys/restoreDeletedQuestion",
  async (args: IDeleteQuestion, { rejectWithValue }) => {
    try {
      const res = await SurveyService.restoreDeletedQuestion(args.surveyId, args.questionId);
      return res.data;
    } catch (err: any) {
      if (err.response.status === 404) {
        return null;
      }

      return rejectWithValue(err);
    }
  }
);

export interface IDeleteQuestion {
  surveyId: string;
  questionId: string;
}


export const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    showAlertMessage: (state, action: PayloadAction<IAlertMessageState>) => {
      state.alertMessage = {
        show: true,
        message: action.payload.message,
        type: action.payload.type,
      }
    },
    ClearAlertMessage: (state) => {
      state.alertMessage = {
        ...state.alertMessage,
        show: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyFolders.pending, (state: ISurveyState, _) => {
        state.surveyFolders = {
          loading: true,
          failed: false,
          folders: [],
        };
      })
      .addCase(
        fetchSurveyFolders.fulfilled,
        (
          state: ISurveyState,
          action: PayloadAction<ISurveyFolder[] | null>
        ) => {
          state.surveyFolders = {
            loading: false,
            failed: false,
            folders: action!.payload!,
          };
        }
      )
      .addCase(fetchSurveyFolders.rejected, (state: any, action: any) => {
        state.surveyFolders = {
          loading: true,
          failed: true,
          folders: [],
        };
      })
      .addCase(fetchSurveys.pending, (state: ISurveyState, _) => {
          state.surveys.loading = true;
          state.surveys.failed = false;
      })
      .addCase(
        fetchSurveys.fulfilled,
        (state: ISurveyState, action: PayloadAction<IGetSurveysResponse | null>) => {
          state.surveys = {
            loading: false,
            failed: false,
            surveys: action!.payload!.surveys,
            total: action!.payload!.total
          };
        }
      )
      .addCase(fetchSurveys.rejected, (state: any, action: any) => {
        state.surveys = {
          loading: false,
          failed: true,
          surveys: [],
          total: 0
        };
      })
      .addCase(fetchSurvey.pending, (state: ISurveyState, _) => {
        state.activeSurvey = {
          loading: true,
          failed: false,
          survey: null,
        };
      })
      .addCase(
        fetchSurvey.fulfilled,
        (state: ISurveyState, action: PayloadAction<ISurvey | null>) => {
          state.activeSurvey = {
            loading: false,
            failed: false,
            survey: action.payload!,
          };
        }
      )
      .addCase(fetchSurvey.rejected, (state: any, action: any) => {
        state.activeSurvey = {
          loading: false,
          failed: true,
          survey: null,
        };
      })

      .addCase(updateSurveyAsync.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(updateSurveyAsync.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(updateSurveyAsync.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(copySurveyQuestion.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(copySurveyQuestion.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(copySurveyQuestion.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(moveSurveyFolder.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(moveSurveyFolder.fulfilled, (state: ISurveyState, _) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(moveSurveyFolder.rejected, (state: ISurveyState, _) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(deleteSurvey.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(deleteSurvey.fulfilled, (state: ISurveyState, _) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(deleteSurvey.rejected, (state: ISurveyState, _) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(moveSurveyQuestion.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(moveSurveyQuestion.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(moveSurveyQuestion.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(updateSurveyOptions.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(updateSurveyOptions.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(updateSurveyOptions.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(updateSurveyLogic.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(updateSurveyLogic.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(updateSurveyLogic.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(uploadLogo.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(uploadLogo.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(uploadLogo.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })

      .addCase(deleteLogo.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(deleteLogo.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(deleteLogo.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(deleteQuestion.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(deleteQuestion.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(fetchDeletedQuestions.pending, (state: ISurveyState, _) => {
        state.inActiveQuestions = {
          loading: true,
          failed: false,
          deletedquestion:null,
        };
      })
      .addCase(
        fetchDeletedQuestions.fulfilled,
        (state: ISurveyState, action: PayloadAction<IDeletedQuestions | null>) => {
          state.inActiveQuestions = {
            loading: false,
            failed: false,
            deletedquestion: action!.payload!,
          };
        }
      )
      .addCase(fetchDeletedQuestions.rejected, (state: any, action: any) => {
        state.inActiveQuestions = {
          loading: false,
          failed: true,
          survey: null,
        };
      })
      .addCase(restoreDeletedQuestions.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(restoreDeletedQuestions.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(restoreDeletedQuestions.rejected, (state: any, action: any) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      })
      .addCase(addDragDropSurveyQuestion.rejected, (state: any, action: any) => {
          state.updating = {
            processing: false,
            failed: true,
            processed: false,
          };
        })
      .addCase(addDragDropSurveyQuestion.pending, (state: ISurveyState, _) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(addDragDropSurveyQuestion.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(addSurveyFolders.pending, (state: ISurveyState) => {
        state.updating = {
          processing: true,
          failed: false,
          processed: false,
        };
      })
      .addCase(addSurveyFolders.fulfilled, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: false,
          processed: true,
        };
      })
      .addCase(addSurveyFolders.rejected, (state: ISurveyState) => {
        state.updating = {
          processing: false,
          failed: true,
          processed: false,
        };
      });
  },
});

const alertMessageAction = surveysSlice.actions.showAlertMessage
const getSurveys = (state: RootState) => state.surveys.surveys;

const getActiveSurvey = (state: RootState) => state.surveys.activeSurvey;

const getSurveyFolders = (state: RootState) => state.surveys.surveyFolders;

const getUpdateStatus = (state: RootState) => state.surveys.updating;

const alertMessageInfo = (state: RootState) => state.surveys.alertMessage
const getInActiveQuestions = (state: RootState) => state.surveys.inActiveQuestions;

export {
  fetchSurveys,
  deleteSurvey,
  fetchSurveyFolders,
  fetchSurvey,
  addSurveyFolders,
  updateSurveyAsync,
  moveSurveyQuestion,
  copySurveyQuestion,
  updateSurveyOptions,
  updateSurveyLogic,
  uploadLogo,
  deleteLogo,
  deleteQuestion,
  fetchDeletedQuestions,
  restoreDeletedQuestions,
  addDragDropSurveyQuestion,
  getSurveys,
  getSurveyFolders,
  getActiveSurvey,
  getUpdateStatus,
  getInActiveQuestions,
  moveSurveyFolder,
  alertMessageInfo
};
export const alertMessageClearAction = surveysSlice.actions.ClearAlertMessage
export default surveysSlice.reducer;
