import { createContext, useMemo, useState } from "react";

export interface HeaderContextType {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

const HeaderContextProvider = ({ children, visible: initialSettings }: any) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const setVisible = (visible: boolean) => {
    setIsVisible(visible);
  };

  useMemo(() => {
    setVisible(initialSettings);
  }, [initialSettings]);

  return (
    <HeaderContext.Provider value={{ visible: isVisible, setVisible: setVisible }}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderContextProvider };
