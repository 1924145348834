export const ADD_FOLDER_SUCCESS = "Folder added successfully!"
export const FOLDER_ALREADY_EXISTS = "Failed to add folder. folder already exists"
export const ADD_FOLDER_FAILED = "Failed to add folder. Please try again."
export const DELETE_SURVEY_SUCCESS="Survey deleted successfully!"
export const DELETE_SURVEY_FAILED="Failed to delete survey. Please try again."
export const MOVE_FOLDER_SUCCESS="Move folder successfully!"
export const MOVE_FOLDER_FAILED="Failed to move folder. Please try again."
export const QUESTION_SAVE_SUCCESS="Question has been saved "
export const QUESTION_SAVE_FAILED="Question has not been saved"
export const QUESTION_TEXT_REQUIRED="You must enter a question"
export const REQUIRE_ANSWER_REQUIRED="This input field cannot be empty"
export const CHOICE_MINIMUM_TWO_REQUIRED="At least two text fields should be filled"
export const CHOICE_LAYOUT_REQUIRED="Layout field is required"
export const RESPONDENTS_ANSWER_REQUIRED="Select any one value"
export const ROW_MINIMUM_ONE_REQUIRED="At least one text fields should be filled"
export const FILE_EXTENSIONS_ONE_REQUIRED="Please select at least one file type."
