import * as React from "react";
import styled from "@emotion/styled";
import { Snackbar, Alert } from "@mui/material";
import { Check, Error } from "@mui/icons-material";
import { alertMessageClearAction, alertMessageInfo } from "../../slices";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { alertMessageType } from "../../types/surveys";
interface ICustomSnackbar {
    vertical?: "top" | "bottom";
    horizontal?: "left" | "center" | "right";
}
const CustomSnackbar = ({
    vertical = "bottom",
    horizontal = "center",
}: ICustomSnackbar) => {
    const dispatch = useAppDispatch();
    const { message, show, type } = useAppSelector(alertMessageInfo);
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(alertMessageClearAction());
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={show}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <CustomAlert
                    severity={type}
                    icon={
                        type === alertMessageType.Success ? (
                            <Check />
                        ) : type === alertMessageType.Error ? (
                            <Error />
                        ) : (
                            ""
                        )
                    }
                    sx={{
                        background:
                            type === alertMessageType.Success
                                ? "#00AB3A"
                                : type === alertMessageType.Error
                                    ? "#E02424"
                                    : "#35a5d3",
                    }}
                >
                    {type === alertMessageType.Success
                        ? "Success"
                        : type === alertMessageType.Error
                            ? "Error"
                            : "Info"}
                    : {message}
                </CustomAlert>
            </Snackbar>
        </div>
    );
};
export default CustomSnackbar;
export const CustomAlert = styled(Alert)({
    borderRadius: "8px",
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    svg: {
        color: "white",
        width: "24px",
        height: "24px",
    },
});
