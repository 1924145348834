// All user related database operations can be defined here.
import {
    GET_SURVEY,
    GET_SURVEYS,
    GET_SURVEY_FOLDERS,
    UPDATE_SURVEY,
    MOVE_SURVEY_QUESTION,
    COPY_SURVEY_QUESTION,
    UPDATE_QUESTION_OPTIONS,
    UPDATE_QUESTION_LOGIC,
    UPLOAD_LOGO,
    DELETE_LOGO,
    DELETE_QUESTION,
    GET_DELETED_QUESTIONS,
    RESTORE_QUESTION,
    ADD_DRAGDROP_SURVEY_QUESTION,
    ADD_SURVEY_FOLDERS,
    MOVE_SURVEY_FOLDER,
    DELETE_SURVEY
  } from "./CONSTANTS";
  import http from "../http-common";
import { IDragDropQuestionSave, IManipulationResult, IMoveSurveyFolderRequest, IQuestionLogic, IQuestionOptions, ISurvey } from "../types/surveys";
  
  class SurveysDataService {
    getSurveys(folderId?: string, searchTerms?: string,page?:number, limit?:number ) {
      return http.get(GET_SURVEYS(folderId, searchTerms,page,limit), { withCredentials: true});
    }

    getSurvey(surveyId: string) {
      return http.get(GET_SURVEY(surveyId), { withCredentials: true});
    }

    deleteSurvey(surveyId: string) {
      return http.delete(DELETE_SURVEY(surveyId), { withCredentials: true});
    }

    getSurveyFolders() {
      return http.get(GET_SURVEY_FOLDERS, { withCredentials: true});
    }

    addSurveyFolders(folderName:string) {
      return http.post(ADD_SURVEY_FOLDERS,{folderName});
    }

    updateSurvey(surveyId: string, model: ISurvey) {
      return http.put(UPDATE_SURVEY(surveyId), model, { withCredentials: true});
    }

    moveSurveyQuestion(surveyId: string, model: IManipulationResult) {
      return http.post(MOVE_SURVEY_QUESTION(surveyId, model), {
        beforeAfter: model.beforeAfter,
        pageId: model.pageId,
        targetQuestionId: model.targetQuestionId
      });
    }

    moveSurveyFolder({surveyId, folderId}: IMoveSurveyFolderRequest) {
      return http.put(MOVE_SURVEY_FOLDER(surveyId),{
        folderId: folderId
      });
    }

    copySurveyQuestion(surveyId: string, model: IManipulationResult) {

      return http.post(COPY_SURVEY_QUESTION(surveyId, model), {
        beforeAfter: model.beforeAfter,
        pageId: model.pageId,
        targetQuestionId: model.targetQuestionId
      });
    }

    addDragDropSurveyQuestion(surveyId: string, model: IDragDropQuestionSave) {
      return http.post(ADD_DRAGDROP_SURVEY_QUESTION(surveyId), model);
    }

    uploadLogo(model: any) {
      const formData = new FormData();
      formData.append("surveyId", model.surveyId);
      formData.append("File", model.file);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      return http.post(UPLOAD_LOGO, formData,config);
    }

    deleteLogo(surveyId: string) {
      return http.delete(DELETE_LOGO(surveyId),{ withCredentials: true});
    }

    updateQuestionOptions(surveyId: string, questionId: string, options: IQuestionOptions) {
      return http.put(UPDATE_QUESTION_OPTIONS(surveyId, questionId), options);
    }

    updateQuestionLogic(surveyId: string, questionId: string, logic: IQuestionLogic[]) {
      return http.put(UPDATE_QUESTION_LOGIC(surveyId, questionId), logic);
    }

    deleteQuestion(surveyId: string, questionId: string) {
      return http.delete(DELETE_QUESTION(surveyId,questionId),{ withCredentials: true});
    }

    getDeletedQuestions(surveyId: string) {
      return http.get(GET_DELETED_QUESTIONS(surveyId),{ withCredentials: true});
    }

    restoreDeletedQuestion(surveyId: string, questionId: string) {
      return http.put(RESTORE_QUESTION(surveyId,questionId),{ withCredentials: true});
    }

  }
  
  const dataservice = new SurveysDataService();
  
  export default dataservice;
