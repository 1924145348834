import { Suspense } from "react";
import "./App.css";
import Routes from "./router";
import { Provider } from "react-redux";
import { store } from "./store";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Barlow",
      fontSize: 16
    },
    palette: {
      background: {
        default: "#fafafa"
      },
      primary:{
        main: "#242836"
      },
      secondary: {
        main: "#0100D8"
      }
    },
   /* components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#3E4652",
              width: "5px"
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              backgroundColor: "#3e4652",
              minHeight: 16,
              border: "1px solid #2b2b2b",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#3e4652",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#3e4652",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                backgroundColor: "#3e4652",
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          },
        },
      },
    },*/
  });

  return (
    <Suspense fallback={<></>}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>{Routes.useRenderRoutes()}</Provider>
    </ThemeProvider>
    </Suspense>
  );
}

export default App;
