import { Box, styled } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { getActiveSurvey, getUpdateStatus } from "../../slices";
import { SMDetailText1 } from "../typography";
import CircularProgress from '@mui/material/CircularProgress';
interface SavingLoading$Props {
  message?: string
}

const SavingLoading = ({ message }: SavingLoading$Props) => {
  const { processing: isUpdateLoading } = useAppSelector(getUpdateStatus);
  const { loading: activeLoading } = useAppSelector(getActiveSurvey);

  return isUpdateLoading || activeLoading ? <>
    <LoaderWrapper>
      <CircularProgress
        size={50}
        thickness={4}
        color="primary"
        style={{ color: "#0100D8" }}
      />
      <SMDetailText1>{message ? message : "Saving questions..."}</SMDetailText1>
    </LoaderWrapper>
  </> : <></>
};

export default SavingLoading;
const LoaderWrapper = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  zIndex: 99,
  backdropFilter: "blur(5px)",
  background: 'rgba(36, 40, 54, 0.15)',
});

