const PhoneIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_1142_2354)">
                <path d="M15.4116 13.626C15.5154 13.5569 15.6349 13.5147 15.7591 13.5034C15.8834 13.4922 16.0085 13.512 16.1231 13.5613L20.5444 15.5422C20.6934 15.6059 20.8177 15.7161 20.8989 15.8564C20.98 15.9967 21.0135 16.1594 20.9944 16.3203C20.8487 17.4088 20.3127 18.4074 19.486 19.1303C18.6593 19.8531 17.5982 20.2512 16.5 20.2503C13.1185 20.2503 9.87548 18.907 7.48439 16.516C5.0933 14.1249 3.75 10.8819 3.75 7.50035C3.74916 6.40216 4.1472 5.34105 4.87009 4.51435C5.59298 3.68764 6.59152 3.15162 7.68 3.00597C7.84091 2.98686 8.00368 3.02036 8.14395 3.10148C8.28422 3.1826 8.39444 3.30697 8.45813 3.45597L10.4391 7.88097C10.4877 7.99462 10.5076 8.11854 10.4968 8.24171C10.486 8.36487 10.4449 8.48346 10.3772 8.58691L8.37375 10.9691C8.30269 11.0763 8.26066 11.2002 8.25179 11.3285C8.24291 11.4568 8.26749 11.5853 8.32313 11.7013C9.09844 13.2885 10.7391 14.9094 12.3309 15.6772C12.4475 15.7326 12.5766 15.7566 12.7053 15.7469C12.834 15.7372 12.958 15.6941 13.065 15.6219L15.4116 13.626Z" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1142_2354">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default PhoneIcon;