import styled from "@emotion/styled";
import { Box, Divider, Grid, MenuItem } from "@mui/material";
import { PageContainer } from "../page";
import LinkedinIcon from "../../icons/LinkedinIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import YoutubeIcon from "../../icons/YoutubeIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import PhoneIcon from "../../icons/PhoneIcon";
import EnvelopeIcon from "../../icons/EnvelopeIcon";
import FullLogoIcon from "../../icons/FullLogoIcon";
interface IItemList {
  icon?: JSX.Element;
  label: string;
  link: string;
}
const NavigationItem: IItemList[] = [
  {
    label: "Survey Home",
    link: process.env.REACT_APP_SITE_PATH + "survey_home.aspx",
  },
  {
    label: "Plans & Pricing",
    link: process.env.REACT_APP_SITE_PATH + "survey_home.aspx",
  },
  {
    label: "My Account",
    link: process.env.REACT_APP_SITE_PATH + "AccountView.aspx",
  },
  { label: "Help Center", link: "https://help.surveymethods.com/" },
];
const followUsItem: IItemList[] = [
  {
    icon: <LinkedinIcon />,
    label: "LinkedIn",
    link: process.env.REACT_APP_LINKEDIN!,
  },
  {
    icon: <FacebookIcon />,
    label: "Facebook",
    link: process.env.REACT_APP_FACEBOOK!,
  },
  {
    icon: <YoutubeIcon />,
    label: "YouTube",
    link: process.env.REACT_APP_YOUTUBE!,
  },
  {
    icon: <TwitterIcon />,
    label: "Twitter (X)",
    link: process.env.REACT_APP_TWITTER!,
  },
];
const ContactUsItem: IItemList[] = [
  { icon: <PhoneIcon />, label: "800-601-2462", link: "" },
  { icon: <PhoneIcon />, label: "214-257-8909", link: "" },
  {
    icon: <EnvelopeIcon />,
    label: "smsupport@surveymethods.net",
    link: "mailto:smsupport@surveymethods.net",
  },
];
const Footer = () => {
  return (
    <FooterContainer container>
      <FooterMainWrap>
        <Grid container spacing={3}>
          <CustomGrid item xs={12} lg={5} md={4}>
            <LogoWrapper>
              <LogoBox>
                <LogoContainer>
                  <FullLogoIcon />
                </LogoContainer>
              </LogoBox>
            </LogoWrapper>
          </CustomGrid>
          <CustomGrid item xs={6} lg={2} md={2}>
            <NavigationHead>Navigation</NavigationHead>
            <NavigationWrap>
              {NavigationItem.map((i: any, index: number) => (
                <MenuItemList key={index}>
                  <ListText>{i.label}</ListText>
                </MenuItemList>
              ))}
            </NavigationWrap>
          </CustomGrid>
          <CustomGrid item xs={6} lg={2} md={2}>
            <NavigationHead>Follow Us</NavigationHead>
            <NavigationWrap>
              {followUsItem.map((i: IItemList, index: number) => (
                <MenuItemList key={index}>
                  <a href={i.link} target="_blank">
                    <IconWrap>{i.icon}</IconWrap>
                    <ListText>{i.label}</ListText>
                  </a>
                </MenuItemList>
              ))}
            </NavigationWrap>
          </CustomGrid>
          <CustomGrid item xs={12} lg={3} md={4}>
            <NavigationHead>Contact Us</NavigationHead>
            <NavigationWrap>
              {ContactUsItem.map((i: any, index: number) => {
                return i.link.length > 0 ? (
                  <MenuItemList key={index}>
                    <a href={i.link} target="_blank">
                      <IconWrap>{i.icon}</IconWrap>
                      <ListText>{i.label}</ListText>
                    </a>
                  </MenuItemList>
                ) : (
                  <MenuItemList key={index}>
                    <a>
                    <IconWrap>{i.icon}</IconWrap>
                    <ListText>{i.label}</ListText>
                    </a>
                  </MenuItemList>
                );
              })}
            </NavigationWrap>
          </CustomGrid>
        </Grid>
      </FooterMainWrap>
      <FooterMainWrap>
        <FooterDivider />
        <FooterTermWrap>
          <TermsWrap>
            <Box><a target="_blank" href="https://surveymethods.com/terms-of-use/">Terms of Service</a></Box>
            <Box><a  target="_blank" href="https://surveymethods.com/privacy-policy/">Privacy Policy</a></Box>
          </TermsWrap>
          <Box>© 2003-2024 SurveyMethods LLC. All rights reserved.</Box>
        </FooterTermWrap>
      </FooterMainWrap>
    </FooterContainer>
  );
};
export default Footer;

const FooterContainer = styled(Grid)({
  background: "#242836",
  color: "#fff",
  padding: "0",
});
const FooterMainWrap = styled(PageContainer)({
  margin: "24px auto",
  "@media (max-width: 900px)": {
    width: "100%",
  },
});
const CustomGrid = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
const ListText = styled("div")({
  fontSize: "16px",
  fontWeight: "400",
  wordBreak: "break-all",
  color: "#fff",
  textDecoration: "none"
});
const FooterTermWrap = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#9297A8",
  gap: "24px",
  "@media (max-width: 900px)": {
    flexDirection: "column",
  },
});
const LogoWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const DetailsText = styled("p")({
  margin: 0,
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
});

const LogoContainer = styled("div")({
  background: "#fff",
  width: "275px",
  textAlign: "center",
  height: "100px",
  borderRadius: "5px",
});

const LogoBox = styled(Box)({
  svg: {
    width: "250px",
    height: "100px",
  },
});
const TermsWrap = styled(Box)({
  display: "flex",
  gap: "24px",
  "@media (max-width: 900px)": {
    div: {
      width: "100%",
    },
  },
  a: {
    textDecoration: "none",
    color: "rgb(146, 151, 168)"
  }
});
const NavigationWrap = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "0",
  gap: "16px",
  li: {
    padding: 0,
    display: "flex",
    gap: "8px",
    color: "#FFF",
    fontSize: "16px",
    fontWeight: 400,
    minHeight: "0",
    lineHeight: "24px",
  },
});
const FooterDivider = styled(Divider)({
  borderTop: "1px solid #3F475D",
  marginBottom: "24px",
});
const NavigationHead = styled("div")({
  color: "#FFF",
  fontSize: "18px",
  fontWeight: 600,
});
const IconWrap = styled("div")({
  Width: "24px",
  Height: "24px",
  display: "flex",
  marginRight: "5px",
  alignItems: "center",
});
const MenuItemList = styled(MenuItem)({
  a: {
    display: "flex",
    textDecoration: "none"
  },
  "&:hover": {
    transition: "all 0.5s ease",
    color: "#f5f5f5",
    background: "transparent",
  },
});
