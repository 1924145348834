import { Button, ButtonGroup, styled } from "@mui/material";


/* Designed Buttons */

export const Buttons = styled(Button)({
":hover": {
  opacity: ".8"
}
})

export const PrimaryButton = styled(Buttons)({
  background: "#0100D8",
  textTransform: "none",
  border: "none",
  color: "#fff",
  padding: "8px 16px",
  borderRadius: "8px",
  display: "flex",
  gap: "8px",
  ":hover": {
    background: "#0100D8",
  },
});

export const UpgradeButton = styled(PrimaryButton)({
  background: "rgb(249, 190, 0)",
  fontWeight: 700,
  color: "#333",
  ":hover": {
    background: "rgb(249, 190, 0)",
  },
})

export const SecondaryButton = styled(PrimaryButton)({
  background: "#fff",
  border: "solid 1px #D0D5E9",
  color: "#0100D8",
  display: "flex",
  gap: "8px",
  ":hover": {
    background: "#fff",
  },
});

export const FoldersButton = styled(PrimaryButton)({
  background: "#fff",
  border: "solid 1px #A7A7A7",
  color: "#3F475D",
  display: "flex",
  gap: "8px",
  ":hover": {
    background: "#fff",
  },
  "& h5": {
    color: "var(--Text-Text-dark-grey, #3F475D)",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
  },
});
export const CancelButton = styled(SecondaryButton)({
  width: "50%",
});
export const NextButton = styled(PrimaryButton)({
  width: "50%",
});
export const ResumeButton = styled(SecondaryButton)({
  display: "flex",
  height: "40px",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  borderRadius: "8px",
  border: "1px solid var(--Light-blue, #D0D5E9)",
  background: "var(--White, #FFF)"
});

export const SMSaveButton = styled(Button)({
  background: "#9297A8",
  color: "#fafafa80",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
  borderRadius: "8px",
  height: "40px",
  padding: "8px 16px",
  textTransform: "capitalize",
  ":hover": {
    background: "#9297A8",
  },
});

export const MenuButton = styled(PrimaryButton)({
  background: "#fff",
  border: "none",
  color: "#242836",
  ":hover": {
    background: "#fff",
  },
  svg: {
    marginLeft: "5px",
  },
});


export const GridToggle = styled(ButtonGroup)({
  background: "#fff",
  border:"solid 1px #D0D5E9",
})

export const GridToggleButton = styled(Button)({
  background: "none",
  textTransform: "none",
  color: "#9297A8",
  "&.selected": {
    background: "#9297A8",
    color: "#fff"
  }
})

export const CardButton = styled(SecondaryButton)({
  color: "#3F475D",
  fontWeight: 500,
  border: "solid 1px #A7A7A7",
  width: "100%",
  marginTop: "16px"
})


export const SMCustomButton = styled(SecondaryButton)({
  display: "flex",
  height: "40px",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: 8,
  color: "#3F475D",
  borderRadius: "8px",
  border: "1px solid var(--Light-blue, #D0D5E9)",
  background: "var(--White, #FFF)",
  cursor: "pointer"
})



const DefaultButton = styled(Buttons)({
  textTransform: "none",
  fontSize: "1rem",
});






export const TextLink = styled(DefaultButton)({
  padding: 0,
  color: "#0100D8",
  fontSize: '16px',
  fontWeight: "400",
  textDecorationLine: "underline",
  ":hover": {
    textDecorationLine: "underline"
  }
});

export const IconButton = styled(DefaultButton)({
  padding: 0,
  background: "#ddd",
  marginLeft: ".5rem",
  color: "#333",
});

export const IconButtonPlain = styled(DefaultButton)({
  padding: 0,
  width: "auto",
  minWidth: 0,
  background: "none",
  ":hover": {
    color: "#999",
  },
  color: "#ccc",
  marginLeft: ".5rem",
});

export const SMButton = styled(Buttons)({});

export const ActionButton = styled(SMButton)({
  background: "#6B787F",
});

export const SaveButton = styled(SMButton)({
  background: "rgb(0, 191, 111)",
  ":hover": {
    background: "rgb(0, 160, 120)",
    boxShadow: "none",
  },
  boxShadow: "none",
  borderRadius: "4px",
  svg: {
    marginRight: ".5rem",
  },
});

export const GreyButton = styled(SMButton)({
  background: "#6b787f",
  margin: "0 1rem",
  ":hover": {
    background: "#6b756f",
    boxShadow: "none",
  },
  boxShadow: "none",
  borderRadius: "4px",
});

export const SMGroupedButton = styled(SMButton)({
  background: "#fff",
  color: "#333",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  margin: "0",
  ":hover": {
    background: "#6b756f",
  },
  "&.selected": {
    background: "#000099",
    color: "#fff",
  },
});

export const SMButtonGroup = styled(ButtonGroup)({
  button: {
    borderRight: "solid 1px #ddd !important",
  },
});

export const SMAddButton = styled(PrimaryButton)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: "8px",
});
