import { IManipulationResult } from "../types/surveys";

export const GET_SURVEYS = (folderId?: string, searchTerms?: string,page?:number,limit?:number) => {
  let url = `/surveys`;

  if (folderId !== null) url += `?folderId=${folderId}`;

  if (searchTerms !== null  && searchTerms !== "")
    url += (url.indexOf("?") > -1 ? "&" : "?") + `searchTerms=${searchTerms}`;

    url += (url.indexOf("?") > -1 ? "&" : "?") + `from=${page || 0}&pageSize=${limit || 8}`

  return url;
};

export const GET_SURVEY = (surveyId: string) => `/surveys/${surveyId}`;
export const DELETE_SURVEY = (surveyId: string) => `/surveys/${surveyId}`;
export const UPDATE_SURVEY = (surveyId: string) => `/surveys/${surveyId}`;
export const MOVE_SURVEY_FOLDER = (surveyId: string | null ) => `/surveys/${surveyId}/move`;
export const MOVE_SURVEY_QUESTION = (surveyId: string, model: IManipulationResult) => `/surveys/${surveyId}/questions/${model.questionId}/move`
export const COPY_SURVEY_QUESTION = (surveyId: string, model: IManipulationResult) => `/surveys/${surveyId}/questions/${model.questionId}/copy`
export const UPDATE_QUESTION_OPTIONS = (surveyId: string, questionId: string) => `/surveys/${surveyId}/questions/${questionId}/options`
export const UPDATE_QUESTION_LOGIC = (surveyId: string, questionId: string) => `/surveys/${surveyId}/questions/${questionId}/logic`
export const GET_SURVEY_FOLDERS = `/surveys/folders`;
export const ADD_SURVEY_FOLDERS = `/surveys/folders`;
export const UPLOAD_LOGO = `/surveys/upload`;
export const DELETE_LOGO = (surveyId: string) => `/surveys/logo/${surveyId}`;
export const GET_RESULT_SUMMARY = (surveyId: string) => `/analyze/${surveyId}/summary`;
export const DELETE_QUESTION = (surveyId: string, questionId: string) => `/surveys/${surveyId}/questions/${questionId}`;
export const GET_DELETED_QUESTIONS = (surveyId: string) => `/surveys/${surveyId}/questions/deleted`;
export const RESTORE_QUESTION = (surveyId: string,questionId: string) => `/surveys/${surveyId}/questions/${questionId}/restore`;
export const ADD_DRAGDROP_SURVEY_QUESTION = (surveyId: string) => `/surveys/${surveyId}/dropquestion`

export const GET_USER = "/account/me"