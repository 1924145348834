import { Outlet } from "react-router-dom";
import { Header } from "./header";
import { Box, Container, styled } from "@mui/material";
import { HeaderContextProvider } from "./header-context";
import Footer from "./footer";
import SavingLoading from "../loader/saving-loading";

const Layout = () => {
  return (
    <HeaderContextProvider visible={true}>
      <MainWrapper>
      <Header />
        <Container className="section-content" maxWidth={false} disableGutters={true}>
          <SavingLoading />
        <Outlet />
      </Container>
      <Footer />
      </MainWrapper>
    </HeaderContextProvider>
  );
};

export default Layout;

const MainWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100vh',
  '& .section-content': {
    flex: 1,
  },
});
