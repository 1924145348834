const FacebookIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clipPath="url(#clip0_1142_2330)">
                <path d="M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.25 8.25H14.75C14.1533 8.25 13.581 8.48705 13.159 8.90901C12.7371 9.33097 12.5 9.90326 12.5 10.5V21" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.5 13.5H15.5" stroke="#9297A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1142_2330">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
export default FacebookIcon;