import { useEffect, useState } from "react";
import { Button, Box, Grid, Menu, MenuItem, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SecondaryButton, UpgradeButton } from "../buttons";
import { ExpandLess, ExpandMore, NotificationsNone } from "@mui/icons-material";
import SurveyMethodIcon from "../../icons/SurveyMethodIcon";
import { SMDrawer } from "../modals/drawer-left";
import LogoutIcon from "../../icons/LogoutIcon";
import CloseIcon from "../../icons/CloseIcon";
import useWindowResize from "../../hooks/useWindowResize";
import { IHeaderMobileDrawerView } from "../../types/surveys";
import MenuIcon from "../../icons/MenuIcon";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchUser, getUser } from "../../slices";

export const Header = () => {
  const navigate = useNavigate();
  const { width } = useWindowResize();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = (
    arg: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setDrawerOpen(false);
  };

  const user = useAppSelector(getUser);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  return (
    <HeaderContainer container>
      <HeaderPlaceholder container justifyContent={"space-between"}>
        <Grid item style={{ display: "flex" }}>
          <LogoIconBtn
            variant="text"
            disableRipple
            disableTouchRipple
            onClick={() => {
              navigate("/");
            }}
          >
            <SurveyMethodIcon />
          </LogoIconBtn>
          <Nav>
            <NavItem className="selected">
              <NavLink
                variant="text"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                variant="text"
                onClick={() => {
                  window.location.href =
                    process.env.REACT_APP_SITE_PATH + "billing/upgrade";
                }}
              >
                Plans & Pricing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                variant="text"
                onClick={() => {
                  window.open("https://help.surveymethods.com");
                }}
              >
                Help Center
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                variant="text"
                onClick={() => {
                  window.location.href =
                    process.env.REACT_APP_SITE_PATH + "survey_home.aspx";
                }}
              >
                Switch Back
              </NavLink>
            </NavItem>
          </Nav>
          <MenuBtn onClick={handleDrawerOpen}>
            <MenuIcon />
          </MenuBtn>
        </Grid>
        <HeaderMain
          alignItems={"center"}
          display={"flex"}
          justifyItems={"space-between"}
        >
          <TeamBox>
            {/*<PrimaryButton>Create team</PrimaryButton>*/}
            <UpgradeButton
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_SITE_PATH + "billing/upgrade";
              }}
            >
              Upgrade
            </UpgradeButton>
            <SecondaryButton
              disableRipple
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_SITE_PATH + "createnewsurvey.aspx";
              }}
            >
              Create survey
            </SecondaryButton>
          </TeamBox>
          <NotificationsNone
            style={{ marginRight: "1rem" }}
            htmlColor={"#9297A8"}
          />
          {width > 900 && (
            <>
              <MenuButtonEmail
                disableRipple
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {user && (
                  <EmailContainer>
                    <span>{user.emailAddress}</span>
                  </EmailContainer>
                )}{" "}
                {!open ? <ExpandMore /> : <ExpandLess />}
              </MenuButtonEmail>
              <Menu
                id="my-account"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "my-account",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      minWidth: "200px",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
              >
                <HeaderMenuItem
                  onClick={() => {
                    handleClose();
                    window.location.href =
                      process.env.REACT_APP_SITE_PATH + "AccountView.aspx";
                  }}
                >
                  My account
                </HeaderMenuItem>

                <HeaderMenuItem
                  onClick={() => {
                    handleClose();
                    window.location.href =
                      process.env.REACT_APP_SITE_PATH + "EmailList.aspx";
                  }}
                >
                  Mailing Lists
                </HeaderMenuItem>
                <HeaderMenuItem
                  onClick={() => {
                    handleClose();
                    window.location.href =
                      process.env.REACT_APP_SITE_PATH + "surveyLibrary.aspx";
                  }}
                >
                  Survey Templates
                </HeaderMenuItem>
                <HeaderMenuItem
                  onClick={() => {
                    handleClose();
                    window.location.href =
                      process.env.REACT_APP_SITE_PATH + "logout";
                  }}
                >
                  Logout
                </HeaderMenuItem>
              </Menu>
            </>
          )}
        </HeaderMain>

        {width < 900 && (
          <SMDrawer
            paperProps={{
              sx: {
                width: "100%",
                height: "100%",
                backgroundColor: "rgb(225 225 225 / 34%)",
                backdropFilter: "blur(5px)",
                color: "#242836",
              },
            }}
            onClose={() => {
              setDrawerOpen(false);
            }}
            anchor="left"
            cartOpen={drawerOpen}
            renderview={RenderCurrentSelection({
              handleDrawerClose: handleDrawerClose,
              navigate,
              user: user!,
            })}
          ></SMDrawer>
        )}
      </HeaderPlaceholder>
    </HeaderContainer>
  );
};

const RenderCurrentSelection = ({
  handleDrawerClose,
  navigate,
  user,
}: IHeaderMobileDrawerView) => {
  return (
    <MenuListWrapper>
      <CloseButton onClick={handleDrawerClose}>
        <CloseIcon />
      </CloseButton>
      <MobileSideBar>
        <BtnGroupWrap>
          {/*<PrimaryButton>Create team</PrimaryButton>*/}
          <NavBar>
          <MobileNavItem className="selected">
            <NavLink
              variant="text"
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </NavLink>
          </MobileNavItem>
          <MobileNavItem>
            <NavLink
              variant="text"
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_SITE_PATH + "/billing/upgrade";
              }}
            >
              Plans & Pricing
            </NavLink>
          </MobileNavItem>
          <MobileNavItem>
            <NavLink
              variant="text"
              onClick={() => {
                window.location.href = "https://help.surveymethods.com";
              }}
            >
              Help Center
            </NavLink>
          </MobileNavItem>
          <MobileNavItem>
            <NavLink
              variant="text"
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_SITE_PATH + "AccountView.aspx";
              }}
            >
              My Account
            </NavLink>
          </MobileNavItem>
          <MobileNavItem>
            <NavLink
              variant="text"
              onClick={() => {
                window.location.href =
                  process.env.REACT_APP_SITE_PATH + "EmailList.aspx";
              }}
            >
              Mailing Lists
            </NavLink>
          </MobileNavItem>
          <MobileNavItem>
            <NavLink
              variant="text"
              onClick={() => {
                window.location.href =
                  "https://www.surveymethods.com/integrations/api/";
              }}
            >
              API
            </NavLink>
          </MobileNavItem>
          <MobileNavItem>
            <NavLink
              variant="text"
              onClick={() => {
                window.location.href =
                  "https://www.surveymethods.com/integrations/zapier/";
              }}
            >
              Zapier
            </NavLink>
          </MobileNavItem>
          </NavBar>
          <SecondaryButton
            onClick={() => {
              window.location.href =
                process.env.REACT_APP_SITE_PATH + "createnewsurvey.aspx";
            }}
          >
            Create survey
          </SecondaryButton>
        </BtnGroupWrap>
        <FooterMenu>
          <MenuButtonLink>{user && <>{user.emailAddress}</>}</MenuButtonLink>
          <LogoutIcon />
        </FooterMenu>
      </MobileSideBar>
    </MenuListWrapper>
  );
};
const MenuListWrapper = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",
  '@media screen and (max-width: 900px)': {
    height: '100vh',
  },
});
const LogoIconBtn = styled(Button)({
  "@media (max-width: 900px)": {
    padding: 0,
    display: "flex",
    minWidth: "unset",
    justifyContent: "flex-start",
    "& svg ": {
      width: "40px",
      height: "40px",
    },
  },
});

const TeamBox = styled(Box)({
  display: "flex",
  margin: "0 !important",
  "@media (max-width: 900px)": {
    display: "none",
  },
});

const CreateNewButton = styled(Button)({
  borderRadius: "8px",
  border: "1px solid var(--Light-blue, #D0D5E9)",
  background: "var(--White, #FFF)",
  color: "var(--text-text-brand, #0100D8)",
  leadingTrim: "both",
  textEdge: "cap",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  padding: "6px 16px",
  display: "none",
  textTransform: "capitalize",
  "@media (max-width: 900px)": {
    display: "flex",
  },
});

const FooterMenu = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "16px",
  borderTop: "1px solid var(--strokes-light-blue, #D0D5E9)",
});

const BtnGroupWrap = styled(Box)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  gap: "8px",
  padding: "16px 16px 0 16px",
  borderTop: "1px solid var(--strokes-light-blue, #D0D5E9)",
});

const MobileSideBar = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  flexGrow: 1,
  position: "relative",
  width: "320px",
  background: "#fff",
  color: "red",
});

const MenuButtonEmail = styled(Button)({
  textTransform: "unset",
  "@media (max-width: 1200px)": {
    fontSize: "14px",
  },
});

const EmailContainer = styled("div")({
  maxWidth: "350px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media (max-width: 1350px)": {
    maxWidth: "100px",
  },
  "@media (max-width: 900px)": {
    maxWidth: "75px",
  }
});

const HeaderMain = styled(Grid)({
  "*": {
    margin: "0 .5rem !important",
  },
  "@media (max-width: 1200px)": {
    "*": {
      margin: "0 .2rem !important",
    },
    "& .MuiButtonBase-root": {
      padding: "7px",
      marginLeft: "0",
      fontSize: "14px",
    },
  },
  "@media (max-width: 900px)": {
    "*": {
      margin: "0 .2rem !important",
    },
  },
});

const HeaderMenuItem = styled(MenuItem)({
  color: "#242836",
  lineHeight: "40px",
  fontWeight: 400,
  fontSize: "16px",
});

const HeaderContainer = styled(Grid)({
  background: "#fff",
  borderBottom: "solid 1px #D0D5E9",
  color: "#fff",
  padding: "0 2rem",
  height: "72px",
  position: "sticky",
  top: 0,
  zIndex: '99',
});

const HeaderPlaceholder = styled(Grid)({
  width: "100%",
  maxWidth: "1600px",
  margin: "0 auto",
  flexWrap: "nowrap",
});

const NavBar = styled("ul")({
  display: "none",
  "@media (max-width: 900px)": {
    listStyle: "none",
    margin: 0,
    paddingLeft: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const Nav = styled("ul")({
  listStyle: "none",
  margin: 0,
  paddingLeft: 0,
  display: "flex",
  alignItems: "center",
  "@media (max-width: 900px)": {
    display: "none",
  },
});

const MobileNavItem = styled("li")({
  listStyle: "none",
  display: "inline-block",
  padding: "8px 12px",
  borderRight: "4px solid transparent",
  width: "100%",

  "&.selected button": {
    color: "#0100D8 ",
  },
  "&:hover": {
    width: "100%",
    background: "rgba(1, 0, 216, 0.05)",
    borderRight: "solid 4px #0100D8",
  },
  "&.selected": {
    width: "100%",
    background: "rgba(1, 0, 216, 0.05)",
    borderRight: "solid 4px #0100D8",
  },
});

const NavItem = styled("li")({
  listStyle: "none",
  display: "inline-block",
  paddingBottom: "8px",
  "@media (max-width: 1200px)": {
    padding: "8px 6px",
    "& .MuiButtonBase-root": {
      margin: "0 6px",
      fontSize: "14px",
    },
  },
  "&.selected button": {
    color: "#0100D8 ",
  },
  "&.selected": {
    borderBottom: "solid 1px #0100D8",
  },
});

const NavLink = styled(Button)({
  textTransform: "none",
  padding: "0",
  margin: "0 16px",
  fontSize: "1rem",
  color: "#242836",
});

const MenuBtn = styled(Button)({
  display: "none",
  "@media (max-width: 900px)": {
    display: "flex",
    padding: 8,
    minWidth: "unset",
    alignItems: "center",
  },
});

const MenuButtonLink = styled(Button)({
  display: "none",
  "@media (max-width: 900px)": {
    display: "flex",
    textTransform: "lowercase",
    alignItems: "center",
  },
});

const CloseButton = styled(Button)({
  position: "absolute",
  top: "20px",
  right: "20px",
  "& svg": {
    width: "24px",
    height: "24px",

    "& path": {
      stroke: "#3F475D",
    },
  },
});
